import {createSelector} from 'reselect';
import { DeviceCommand, DeviceGroup, DeviceGroupAddDevicesResponse, DeviceGroupList, DeviceGroupRemoveDevicesResponse, DeviceGroupTriggerFirmwareUpdateResponse, Thing } from '../../shared/types';


import {RootState} from '../reducers';
import {createOperationResultSelector, operationSelector} from '@indigo-cloud/common-react';
/** 
* CREATE Device Group
*/
const createDeviceGroupSelector = (state: RootState) => {
	return state?.deviceGroup?.createDeviceGroupOperation;
};

const createDeviceGroupOperationSelector = createSelector(createDeviceGroupSelector, (state) => operationSelector(state));
const createDeviceGroupOperationResultSelector = createOperationResultSelector(createDeviceGroupOperationSelector);

const getDeviceGroupsSelector = (state: RootState) => {
	return state?.deviceGroup.getDeviceGroupOperation;
};

const listDeviceGroupsOperationSelector = createSelector(getDeviceGroupsSelector, (state) => operationSelector<DeviceGroupList, Error>(state));
const listDeviceGroupsOperationResultsSelector = createOperationResultSelector(listDeviceGroupsOperationSelector);

const listDeviceGroupsDataSelector = createSelector(listDeviceGroupsOperationResultsSelector, (state) => state?.data);

const executeDeviceGroupCommandSelector = (state: RootState) => {
	return state?.deviceGroup.executeDeviceGroupCommandOperation;
};

const executeDeviceGroupCommandOperationSelector = (id: string) => {
	return createSelector(executeDeviceGroupCommandSelector, (state) => {
		return operationSelector<{jobId: string}, Error>(state[id]);
	})
};
const executeDeviceGroupCommandOperationResultsSelector = (id: string) => createOperationResultSelector(executeDeviceGroupCommandOperationSelector(id));


const setDeviceGroupParameterValueSelector = (state: RootState) => {
	return state?.deviceGroup.setDeviceGroupParameterValuesOperation;
};

const setDeviceGroupParameterValueOperationSelector = (id: string) => {
	return createSelector(setDeviceGroupParameterValueSelector, (state) => {
		return operationSelector<{id: string}, Error>(state[id]);
	})
};
const setDeviceGroupParameterValueOperationResultsSelector = (id: string) => createOperationResultSelector(setDeviceGroupParameterValueOperationSelector(id));


const setDeviceGroupPolicySelector = (state: RootState) => {
	return state?.deviceGroup.setDeviceGroupPolicyOperation;
};

const setDeviceGroupPolicyOperationSelector = (id: string) => {
	return createSelector(setDeviceGroupPolicySelector, (state) => {
		return operationSelector<{id: string}, Error>(state[id]);
	})
};
const setDeviceGroupPolicyOperationResultsSelector = (id: string) => createOperationResultSelector(setDeviceGroupPolicyOperationSelector(id));

const getDeviceGroupByIdentifiersSelector = (id?: string) => createSelector(listDeviceGroupsOperationResultsSelector, (state) => {
	
	return state?.data?.filter((item) => id === item.name)?.[0];
});

const triggerDeviceGroupFirmwareUpdateSelector = (state: RootState) => {
	return state?.deviceGroup.triggerDeviceGroupFirmwareUpdateOperation;
};

const triggerDeviceGroupDevicesAddSelector = (state: RootState) => {
	return state?.deviceGroup.triggerDeviceGroupAddDevicesOperation;
};

const triggerDeviceGroupDevicesRemoveSelector = (state: RootState) => {
	return state?.deviceGroup.triggerDeviceGroupRemoveDevicesOperation;
};

const triggerDeviceGroupFirmwareUpdateOperationSelector = (deviceGroup: string) => {
	return createSelector(triggerDeviceGroupFirmwareUpdateSelector, (state) => {
		return operationSelector<DeviceGroupTriggerFirmwareUpdateResponse, Error>(state[deviceGroup]);
	})
};
const triggerDeviceGroupFirmwareUpdateOperationResultsSelector = (deviceGroup: string) => createOperationResultSelector(triggerDeviceGroupFirmwareUpdateOperationSelector(deviceGroup));

const triggerDeviceGroupDevicesAddOperationSelector = (deviceGroup: string) => {
	return createSelector(triggerDeviceGroupDevicesAddSelector, (state) => {
		return operationSelector<DeviceGroupAddDevicesResponse, Error>(state[deviceGroup]);
	})
};

const triggerDeviceGroupDevicesRemoveOperationSelector = (deviceGroup: string) => {
	return createSelector(triggerDeviceGroupDevicesRemoveSelector, (state) => {
		return operationSelector<DeviceGroupRemoveDevicesResponse, Error>(state[deviceGroup]);
	})
};

const getDeviceGroupInfoSelector = (state: RootState) => {
	return state?.deviceGroup.getDeviceGroupInfoOperation;
};

const getDeviceGroupInfoOperationSelector = (id?: string) => {
	return createSelector(getDeviceGroupInfoSelector, (state) => {
		return operationSelector<DeviceGroup, Error>(state[id || '']);
	})
};

const deleteDeviceGroupSelector = (state: RootState) => {
	return state?.deviceGroup.deleteDeviceGroupOperation;
};

const deleteDeviceGroupOperationSelector = (id: string) => {
	return createSelector(deleteDeviceGroupSelector, (state) => {
		return operationSelector<boolean, Error>(state[id]);
	})
};

const deleteDeviceGroupOperationResultsSelector = (id: string) => createOperationResultSelector(deleteDeviceGroupOperationSelector(id));



const updateDeviceGroupSelector = (state: RootState) => {
	return state?.deviceGroup.updateDeviceGroupOperation;
};

const updateDeviceGroupOperationSelector = createSelector(updateDeviceGroupSelector, (state) => {
	return operationSelector<boolean, Error>(state);
})

const updateDeviceGroupOperationResultsSelector = createOperationResultSelector(updateDeviceGroupOperationSelector);


const getDeviceGroupInfoOperationResultsSelector = (id?: string) => createOperationResultSelector(getDeviceGroupInfoOperationSelector(id));

const getDevicesSelector = (state: RootState) => {
	return state?.deviceGroup.getDeviceGroupDevicesOperation;
};

const listDeviceGroupDevicesOperationSelector = (id: string) => {
	return createSelector(getDevicesSelector, (state) => {
		return operationSelector<Thing[], Error>(state[id]);
	})
};

const listDeviceGroupDevicesOperationPageSelector = (deviceGroup: string, pageNo: number, pageIndexPrefix?: string) => createSelector(getDevicesSelector, (state) => {

	return operationSelector<any, Error>(state?.[deviceGroup])?.result?.[`${pageIndexPrefix}_${pageNo}`]
});

const listDeviceGroupDevicesOperationAllSelector = (deviceGroup: string, pageIndexPrefix = 'page') => createSelector(getDevicesSelector, (state) => {

	const pages = state?.[deviceGroup]?.current;
	let lastPageData: any;
	
	const allData = Object.keys(pages || {}).reduce((previous, currentPageKey) => {
		lastPageData = pages?.[currentPageKey as keyof typeof pages];
		lastPageData.pageIndex = Number(currentPageKey.replace(`${pageIndexPrefix}_`, ''));
		// @ts-ignore
		const currentPageResults = Array.isArray(lastPageData?.data) ? lastPageData.data : [];
		return [
			...previous,
			...currentPageResults || []
		];
	}, [] as DeviceGroup[]);

	return [allData, lastPageData];
});



export const deviceGroupsSelectors = {
	createDeviceGroupOperation: createDeviceGroupOperationSelector,
	createDeviceGroupOperationResult: createDeviceGroupOperationResultSelector,

	getDeviceGroupInfoOperation: getDeviceGroupInfoOperationSelector,
	getDeviceGroupInfoOperationResults: getDeviceGroupInfoOperationResultsSelector,

	deleteDeviceGroupOperation: deleteDeviceGroupOperationSelector,
	deleteDeviceGroupOperationResults: deleteDeviceGroupOperationResultsSelector,

	executeDeviceGroupCommandOperation: executeDeviceGroupCommandOperationSelector,
	executeDeviceGroupCommandOperationResults: executeDeviceGroupCommandOperationResultsSelector,

	setDeviceGroupParameterValuesOperation: setDeviceGroupParameterValueOperationSelector,
	setDeviceGroupParameterValueOperationResults: setDeviceGroupParameterValueOperationResultsSelector,
	
	setDeviceGroupPolicyOperation: setDeviceGroupPolicyOperationSelector,
	setDeviceGroupPolicyOperationResults: setDeviceGroupPolicyOperationResultsSelector,

	updateDeviceGroupOperation: updateDeviceGroupOperationSelector,
	updateDeviceGroupOperationResults: updateDeviceGroupOperationResultsSelector,

	listDeviceGroupsData: listDeviceGroupsDataSelector,
	listDeviceGroupsOperation: listDeviceGroupsOperationSelector,

	listDeviceGroupDevicesOperation: listDeviceGroupDevicesOperationSelector,
	listDeviceGroupDevicesOperationPage: listDeviceGroupDevicesOperationPageSelector,
	listDeviceGroupDevicesOperationAll: listDeviceGroupDevicesOperationAllSelector,

	triggerDeviceGroupDevicesAddOperation: triggerDeviceGroupDevicesAddOperationSelector,
	triggerDeviceGroupDevicesRemoveOperation: triggerDeviceGroupDevicesRemoveOperationSelector,

	triggerDeviceGroupFirmwareUpdateOperation: triggerDeviceGroupFirmwareUpdateOperationSelector,
	triggerDeviceGroupFirmwareUpdateOperationResults: triggerDeviceGroupFirmwareUpdateOperationResultsSelector,

	getDeviceGroupByIdentifiers: getDeviceGroupByIdentifiersSelector
};
