import { DeepReadonlyObject, OperationState, getStateError, getStateInProgress, getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';
import { combineReducers } from 'redux';
import { deviceGroupsActions } from '../../actions';
import { StateType } from 'typesafe-actions';

import { DeviceCommand, DeviceGroup, DeviceGroupAddDevicesResponse, DeviceGroupList, DeviceGroupRemoveDevicesResponse, DeviceGroupTriggerFirmwareUpdateResponse, Thing } from '../../../shared';
import {
	ADD_DYNAMIC_DEVICE_GROUP,
	ADD_DYNAMIC_DEVICE_GROUP_ERROR,
	ADD_DYNAMIC_DEVICE_GROUP_SUCCESS,
	DELETE_DEVICE_GROUP,
	DELETE_DEVICE_GROUP_ERROR,
	DELETE_DEVICE_GROUP_SUCCESS,
	EXECUTE_DEVICE_GROUP_COMMAND,
	EXECUTE_DEVICE_GROUP_COMMAND_ERROR,
	EXECUTE_DEVICE_GROUP_COMMAND_SUCCESS,
	LOAD_DEVICE_GROUPS,
	LOAD_DEVICE_GROUPS_ERROR,
	LOAD_DEVICE_GROUPS_INFO,
	LOAD_DEVICE_GROUPS_SUCCESS,
	LOAD_DEVICE_GROUP_DEVICES,
	LOAD_DEVICE_GROUP_DEVICES_CLEAR,
	LOAD_DEVICE_GROUP_DEVICES_ERROR,
	LOAD_DEVICE_GROUP_DEVICES_SUCCESS,
	LOAD_DEVICE_GROUP_INFO_ERROR,
	LOAD_DEVICE_GROUP_INFO_SUCCESS,
	SET_DEVICE_GROUP_PARAMETERS,
	SET_DEVICE_GROUP_PARAMETERS_ERROR,
	SET_DEVICE_GROUP_PARAMETERS_SUCCESS,
	SET_DEVICE_GROUP_POLICY,
	SET_DEVICE_GROUP_POLICY_ERROR,
	SET_DEVICE_GROUP_POLICY_SUCCESS,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS,
	UPDATE_DYNAMIC_DEVICE_GROUP,
	UPDATE_DYNAMIC_DEVICE_GROUP_ERROR,
	UPDATE_DYNAMIC_DEVICE_GROUP_SUCCESS
} from '../../constants';

export type DeviceGroupState = DeepReadonlyObject<{
	createDeviceGroupOperation: OperationState<any, Error>;
	updateDeviceGroupOperation: OperationState<any, Error>;
	getDeviceGroupOperation: OperationState<DeviceGroupList>;

	getDeviceGroupInfoOperation: {
		[id: string]: OperationState<DeviceGroup>;
	};
	deleteDeviceGroupOperation: {
		[id: string]: OperationState<boolean>;
	};

	triggerDeviceGroupFirmwareUpdateOperation: {
		[agentEndpointId: string]: OperationState<DeviceGroupTriggerFirmwareUpdateResponse>;
	};

	triggerDeviceGroupAddDevicesOperation: {
		[agentEndpointId: string]: OperationState<DeviceGroupAddDevicesResponse>;
	};

	triggerDeviceGroupRemoveDevicesOperation: {
		[agentEndpointId: string]: OperationState<DeviceGroupRemoveDevicesResponse>;
	};

	executeDeviceGroupCommandOperation: {
		[agentEndpointId: string]: OperationState<{ jobId: string }> & { request?: ReturnType<typeof deviceGroupsActions.executeDeviceGroupCommand>['payload'] } | undefined;
	};

	setDeviceGroupParameterValuesOperation: {
		[id: string]: OperationState<{ id: string }> & { request?: ReturnType<typeof deviceGroupsActions.setDeviceGroupParameters>['payload'] } | undefined;
	};

	setDeviceGroupPolicyOperation: {
		[id: string]: OperationState<{ id: string }> & { request?: ReturnType<typeof deviceGroupsActions.setDeviceGroupPolicy>['payload'] } | undefined;
	};

	getDeviceGroupDevicesOperation: {
		[id: string]: OperationState<Thing[]>;
	}
}>;
export const initialDeviceGroupsState: DeviceGroupState = {
	createDeviceGroupOperation: getStateInitial<any, Error>(),
	updateDeviceGroupOperation: getStateInitial<any, Error>(),
	getDeviceGroupDevicesOperation: {},
	getDeviceGroupInfoOperation: {},
	executeDeviceGroupCommandOperation: {},
	setDeviceGroupParameterValuesOperation: {},
	setDeviceGroupPolicyOperation: {},
	deleteDeviceGroupOperation: {},
	getDeviceGroupOperation: getStateInitial(),
	triggerDeviceGroupAddDevicesOperation: {},
	triggerDeviceGroupFirmwareUpdateOperation: {},
	triggerDeviceGroupRemoveDevicesOperation: {}
};

export const deviceGroupsReducers = combineReducers<DeviceGroupState, any>({
	/**
	 * CREATE DEVICE GROUP
	 */
	createDeviceGroupOperation: (state = initialDeviceGroupsState.createDeviceGroupOperation, action: any) => {
		switch (action.type) {
		case ADD_DYNAMIC_DEVICE_GROUP: {
			return getStateInProgress(action.payload);
		}
		case ADD_DYNAMIC_DEVICE_GROUP_SUCCESS: {
			const { response } = action.payload;

			return getStateSuccess(response, state);
		}
		case ADD_DYNAMIC_DEVICE_GROUP_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * EXECUTE DEVICE GROUP COMMAND
	 */
	executeDeviceGroupCommandOperation: (state = initialDeviceGroupsState.executeDeviceGroupCommandOperation, action) => {

		switch (action.type) {
		case EXECUTE_DEVICE_GROUP_COMMAND: {
			const { deviceGroupId } = action.payload;
			return {
				[deviceGroupId]: getStateInProgress(action.payload)
			};
		}
		case EXECUTE_DEVICE_GROUP_COMMAND_SUCCESS: {
			const { deviceGroupId, response } = action.payload;
			return {
				...state,
				[deviceGroupId]: getStateSuccess(response, state[deviceGroupId])
			};
		}
		case EXECUTE_DEVICE_GROUP_COMMAND_ERROR: {
			const { error, deviceGroupId } = action.payload;


			return {
				...state,
				[deviceGroupId]: getStateError(error, state[deviceGroupId])
			};
		}
		default:
			return state;
		}
	},
	/**
	 * SET DEVICE GROUP PARAMETER VALUES
	 */
	setDeviceGroupParameterValuesOperation: (state = initialDeviceGroupsState.setDeviceGroupParameterValuesOperation, action) => {

		switch (action.type) {
		case SET_DEVICE_GROUP_PARAMETERS: {
			const { deviceGroupId } = action.payload;
			return {
				[deviceGroupId]: getStateInProgress(action.payload)
			};
		}
		case SET_DEVICE_GROUP_PARAMETERS_SUCCESS: {
			const { deviceGroupId, response } = action.payload;
			return {
				...state,
				[deviceGroupId]: getStateSuccess(response, state[deviceGroupId])
			};
		}
		case SET_DEVICE_GROUP_PARAMETERS_ERROR: {
			const { error, deviceGroupId } = action.payload;


			return {
				...state,
				[deviceGroupId]: getStateError(error, state[deviceGroupId])
			};
		}
		default:
			return state;
		}
	},
	/**
 * SET DEVICE GROUP POLICY
 */
	setDeviceGroupPolicyOperation: (state = initialDeviceGroupsState.setDeviceGroupPolicyOperation, action) => {

		switch (action.type) {
		case SET_DEVICE_GROUP_POLICY: {
			const { deviceGroupId } = action.payload;
			return {
				[deviceGroupId]: getStateInProgress(action.payload)
			};
		}
		case SET_DEVICE_GROUP_POLICY_SUCCESS: {
			const { deviceGroupId, response } = action.payload;
			return {
				...state,
				[deviceGroupId]: getStateSuccess(response, state[deviceGroupId])
			};
		}
		case SET_DEVICE_GROUP_POLICY_ERROR: {
			const { error, deviceGroupId } = action.payload;


			return {
				...state,
				[deviceGroupId]: getStateError(error, state[deviceGroupId])
			};
		}
		default:
			return state;
		}
	},
	/**
	 * UPDATE DEVICE GROUP
	 */
	updateDeviceGroupOperation: (state = initialDeviceGroupsState.updateDeviceGroupOperation, action) => {
		switch (action.type) {
		case UPDATE_DYNAMIC_DEVICE_GROUP: {
			return getStateInProgress(action.payload);
		}
		case UPDATE_DYNAMIC_DEVICE_GROUP_SUCCESS: {
			const { response } = action.payload;

			return getStateSuccess(response, state);
		}
		case UPDATE_DYNAMIC_DEVICE_GROUP_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	getDeviceGroupDevicesOperation: (state = initialDeviceGroupsState.getDeviceGroupDevicesOperation, action: any) => {
		switch (action.type) {
		case LOAD_DEVICE_GROUP_DEVICES: {
			const { name } = action.payload;
			return {
				...state,
				[name]: {
					...state?.[name],
					status: getStateInProgress(action.payload)?.status

				}
			};
		}
		case LOAD_DEVICE_GROUP_DEVICES_SUCCESS: {
			const { name, response } = action.payload;
			return {
				...state,
				[name]: getStateSuccess({
					...state?.[name]?.current,
					...response
				}, state[name])
			};
		}
		case LOAD_DEVICE_GROUP_DEVICES_ERROR: {
			const { error, name } = action.payload;

			return {
				[name]: getStateError(error)
			};
		}
		case LOAD_DEVICE_GROUP_DEVICES_CLEAR: {
			const { name } = action.payload;
			return {
				[name]: getStateInitial()
			};
		}
		default:
			return state;
		}
	},
	getDeviceGroupInfoOperation: (state = initialDeviceGroupsState.getDeviceGroupInfoOperation, action) => {
		switch (action.type) {
		case LOAD_DEVICE_GROUPS_INFO: {
			const { id } = action.payload;
			return {
				...state,
				[id]: getStateInProgress(action.payload)
			};
		}
		case LOAD_DEVICE_GROUP_INFO_SUCCESS: {
			const { id, response } = action.payload;
			return {
				...state,
				[id]: getStateSuccess(response)
			};
		}
		case LOAD_DEVICE_GROUP_INFO_ERROR: {
			const { error, id } = action.payload;

			return {
				...state,
				[id]: getStateError(error)
			};
		}
		default:
			return state;
		}
	},
	deleteDeviceGroupOperation: (state = initialDeviceGroupsState.deleteDeviceGroupOperation, action: any) => {
		switch (action.type) {
		case DELETE_DEVICE_GROUP: {
			const { deviceGroupId } = action.payload;
			return {
				[deviceGroupId]: getStateInProgress(action.payload)
			};
		}
		case DELETE_DEVICE_GROUP_SUCCESS: {
			const { deviceGroupId, response } = action.payload;
			return {
				[deviceGroupId]: getStateSuccess(response)
			};
		}
		case DELETE_DEVICE_GROUP_ERROR: {
			const { error, deviceGroupId } = action.payload;

			return {
				[deviceGroupId]: getStateError(error)
			};
		}
		default:
			return state;
		}
	},
	getDeviceGroupOperation: (state = initialDeviceGroupsState.getDeviceGroupOperation, action: any) => {
		switch (action.type) {
		case LOAD_DEVICE_GROUPS: {
			return getStateInProgress(action.payload);
		}
		case LOAD_DEVICE_GROUPS_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case LOAD_DEVICE_GROUPS_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},


	triggerDeviceGroupAddDevicesOperation: (state = initialDeviceGroupsState.triggerDeviceGroupAddDevicesOperation, action) => {
		switch (action.type) {
		case TRIGGER_DEVICE_GROUP_ADD_DEVICES: {
			const { deviceGroup } = action.payload;
			return {
				[deviceGroup]: getStateInProgress(action.payload)
			};
		}
		case TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS: {
			const { deviceGroup, response } = action.payload;
			return {
				[deviceGroup]: getStateSuccess(response, state[deviceGroup])
			};

		}
		case TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR: {
			const { error, deviceGroup } = action.payload;

			return {
				[deviceGroup]: getStateError(error, state[deviceGroup])

			};
		}
		default:
			return state;
		}
	},

	triggerDeviceGroupFirmwareUpdateOperation: (state = initialDeviceGroupsState.triggerDeviceGroupFirmwareUpdateOperation, action) => {
		switch (action.type) {
		case TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE: {
			const { deviceGroup } = action.payload;
			return {
				[deviceGroup]: getStateInProgress(action.payload)
			};
		}
		case TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS: {
			const { deviceGroup, response } = action.payload;
			return {
				[deviceGroup]: getStateSuccess(response, state[deviceGroup])
			};

		}
		case TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR: {
			const { error, deviceGroup } = action.payload;

			return {
				[deviceGroup]: getStateError(error, state[deviceGroup])

			};
		}
		default:
			return state;
		}
	},

	triggerDeviceGroupRemoveDevicesOperation: (state = initialDeviceGroupsState.triggerDeviceGroupRemoveDevicesOperation, action) => {
		switch (action.type) {
		case TRIGGER_DEVICE_GROUP_REMOVE_DEVICES: {
			const { deviceGroup } = action.payload;
			return {
				[deviceGroup]: getStateInProgress(action.payload)
			};
		}
		case TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS: {
			const { deviceGroup, response } = action.payload;
			return {
				[deviceGroup]: getStateSuccess(response, state[deviceGroup])
			};

		}
		case TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR: {
			const { error, deviceGroup } = action.payload;

			return {
				[deviceGroup]: getStateError(error, state[deviceGroup])

			};
		}
		default:
			return state;
		}
	}

});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type deviceGroupsReducers = StateType<typeof deviceGroupsReducers>;
